<ion-slides #topSlides class="slides top-slider in-english" [attr.pager]="getPlatformService.isDesktop && pager"
 [options]="topBannerOpts" *ngIf="topBanner && commonService.domDirectionLTR" [ngClass]="[ pager ? '' : 'single-slide']"
 (ionSlideTouchEnd)="startAutoPlay()">
	<ion-slide *ngFor="let dataObj of TopBannerData;index as i">
		<div class="slide-content  pt-100 pl-115" (click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="top-banner-background">
				<img fetchpriority="high" [src]="getPlatformService.isDesktop ? dataObj['image_url_en'] : dataObj['mobile_image_url_en'] ? dataObj['mobile_image_url_en'] : dataObj['image_url_en']"
				 loading="lazy" [ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'" alt="Juman">
			</div>
			<div class="title-section pr-3p pl-1p text-right-rtl" *ngIf="!dataObj['is_default_banner'] && (dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">{{dataObj['banner_title_en']}}</div>
				<div class="text">{{dataObj['banner_description_en']}}</div>
				<div class="login-button mt-20">
					<ion-button aria-label="cta action button" (click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])">
						{{'checkoutStepReview.PlaceOrder' | translate }}
					</ion-button>
				</div>
				<div class="custom-button hide-content">
					<ion-button aria-label="cta action button">
						<div>
							<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="title-section-mobile" *ngIf="!dataObj['is_default_banner']  && (dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="top-caption ion-text-start pl-20">{{dataObj['banner_title_en']}}</div>
				<div class="lower-caption ion-text-start pl-20">{{dataObj['banner_description_en']}}</div>
			</div>
			<div class="right-arrow-mobile hide-content" *ngIf="!dataObj['is_default_banner']">
				<ion-button aria-label="cta action button" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
					<div>
						<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
		<div class="slider-arrow" (click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])"
		 *ngIf="getPlatformService.isDesktop">
			<div class="icon-container" (click)="slideTo(i-1,$event)">
				<ion-icon alt="juman" src="assets/icon/slider-left-arrow.svg"></ion-icon>
			</div>
			<div class="icon-container" (click)="slideTo(i+1,$event)">
				<ion-icon alt="juman" src="assets/icon/slider-left-arrow.svg" class="rotate-arrow"></ion-icon>
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #topArSlides class="slides top-slider in-arabic" [attr.pager]="getPlatformService.isDesktop && pager"
 [options]="topBannerOpts" *ngIf="topBanner && !commonService.domDirectionLTR" (ionSlideTouchEnd)="startAutoPlay()">
	<ion-slide *ngFor="let dataObj of TopBannerData;index as i">
		<div class="slide-content pt-100 pl-115" (click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="top-banner-background">
				<img fetchpriority="high" [src]="getPlatformService.isDesktop ? dataObj['image_url_ar'] : dataObj['mobile_image_url_ar']"
				 loading="lazy" [ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'" alt="Juman">
			</div>
			<div class="title-section pr-3p pl-1p text-right-rtl" *ngIf="!dataObj['is_default_banner']  && (dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">{{dataObj['banner_title_ar']}}</div>
				<div class="text">{{dataObj['banner_description_ar']}}</div>
				<div class="login-button mt-20">
					<ion-button aria-label="cta action button" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
						{{'checkoutStepReview.PlaceOrder' | translate }}
					</ion-button>
				</div>
				<div class="custom-button-arabic-web-home hide-content" *ngIf="!dataObj['is_default_banner']">
					<ion-button aria-label="cta action button">
						<div>
							<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>

			</div>
			<div class="title-section-mobile-arabic" *ngIf="!dataObj['is_default_banner']  && (dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="top-caption ion-text-start pl-20">{{dataObj['banner_title_ar']}}</div>
				<div class="lower-caption ion-text-start pl-20">{{dataObj['banner_description_ar']}}</div>
			</div>
			<div class="right-arrow-mobile-arabic hide-content" *ngIf="!dataObj['is_default_banner']">
				<ion-button aria-label="cta action button" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
					<div>
						<ion-icon alt="juman" class="rotate-180" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
		<div class="slider-arrow" (click)="bannerToProduct(dataObj , dataObj['redirecton_details'], dataObj['banner_id'])"
		 *ngIf="getPlatformService.isDesktop">
			<div class="icon-container" (click)="slideTo(i-1,$event)">
				<ion-icon alt="juman" alt="juman" src="assets/icon/slider-left-arrow.svg" class="rotate-arrow"></ion-icon>
			</div>
			<div class="icon-container" (click)="slideTo(i+1,$event)">
				<ion-icon alt="juman" alt="juman" src="assets/icon/slider-left-arrow.svg"></ion-icon>
			</div>
		</div>
	</ion-slide>
</ion-slides>
<div class="secure-support-block" *ngIf="topBanner">
	<div class="section">
		<img src="/assets/icon/secure.svg" loading="lazy" alt="Juman" height="100%" width="100%">
		<div class="right-side">
			<span class="title">{{"secure.securePayment" | translate}}</span>
			<span class="text">{{"secure.description" | translate}}</span>
		</div>
	</div>
	<div class="vertical-line"></div>
	<div class="section">
		<img src="/assets/icon/support.svg" loading="lazy" alt="Juman" height="100%" width="100%">
		<div class="right-side">
			<span class="title">{{"support.24by7support" | translate}}</span>
			<span class="text">{{"support.description" | translate}}</span>
		</div>
	</div>
</div>
<ion-slides #slides class="slides middle-banner" (ionSlideTouchEnd)="startAutoPlay()" (ionSlideTransitionEnd)="slideChanged()"
 [options]="middleSlideOpts" *ngIf="multiBanner && !commonService.domDirectionLTR" dir="rtl">
	<ion-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content  pt-100 pl-115" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
		 [ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl" *ngIf=" (dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">{{ dataObj['banner_title_ar'] }}</div>
				<div class="text">{{ dataObj['banner_description_ar'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_ar'] }}" loading="lazy" [ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'"
				 alt="Juman">
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slides class="slides middle-banner" (ionSlideTransitionEnd)="slideChanged()" (ionSlideTouchEnd)="startAutoPlay()"
 [options]="slideOpts" *ngIf="multiBanner && commonService.domDirectionLTR" dir="ltr">
	<ion-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content  pt-100 pl-115" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
		 [ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl" *ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">{{ dataObj['banner_title_en'] }}</div>
				<div class="text">{{ dataObj['banner_description_en'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Juman">
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slidesMobile class="mobile-slides" (ionSlideTouchEnd)="startAutoPlay()" [options]="middleSlideOpts" *ngIf="multiBanner && commonService.domDirectionLTR">
	<ion-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content-mobile" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Juman">
			</div>
			<div class="title-section ion-text-start" *ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">
					{{ dataObj['banner_title_en'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_en'] }}
				</div>
			</div>
			<div class="custom-button hide-content">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slidesMobileAr class="mobile-slides" (ionSlideTouchEnd)="startAutoPlay()" [options]="middleSlideOpts"
 *ngIf="multiBanner && !commonService.domDirectionLTR">
	<ion-slide *ngFor="let dataObj of bottomBannerData">
		<div class="slide-content-mobile" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile-arabic">
				<img fetchpriority="high" src="{{ dataObj['image_url_ar'] }}" alt="Juman">
			</div>
			<div class="title-section-arabic ion-text-start" *ngIf="(dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">
					{{ dataObj['banner_title_ar'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_ar'] }}
				</div>
			</div>
			<div class="custom-button-arabic hide-content">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="juman" class="rotate-180" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slides class="slides middle-banner" (ionSlideTouchEnd)="startAutoPlay()" (ionSlideTransitionEnd)="slideChanged()"
 [options]="slideOpts" *ngIf="gridBanner && commonService.domDirectionLTR" dir="ltr">
	<ion-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content grid-slide-content pt-65 pl-115" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
		 [ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl" *ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">{{ dataObj['banner_title_en'] }}</div>
				<div class="text">{{ dataObj['banner_description_en'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="grid-image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Juman">
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slidesGridAr class="slides middle-banner" (ionSlideTouchEnd)="startAutoPlay()" (ionSlideTransitionEnd)="slideChanged()"
 [options]="gridSlideOpts" *ngIf="gridBanner && !commonService.domDirectionLTR" dir="rtl">
	<ion-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content grid-slide-content pt-65 pl-115" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])"
		 [ngStyle]="{'background': 'url(' + dataObj['gray_image_url'] + ') no-repeat 0 0 / cover'}">
			<div class="title-section pr-3p pl-1p text-right-rtl" *ngIf="(dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">{{ dataObj['banner_title_ar'] }}</div>
				<div class="text">{{ dataObj['banner_description_ar'] }}</div>
				<div class="custom-button multi-banner-button hide-content">
					<ion-button aria-label="cta action button">
						<div class="button-text">
							{{"ctaAction" | translate}}
						</div>
						<div>
							<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
						</div>
					</ion-button>
				</div>
			</div>
			<div class="grid-image-section">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Juman">
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slidesGridMobile class="mobile-slides" [options]="gridSlideOpts" (ionSlideTouchEnd)="startAutoPlay()"
 *ngIf="gridBanner && commonService.domDirectionLTR">
	<ion-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content-mobile" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile">
				<img fetchpriority="high" src="{{ dataObj['image_url_en'] }}" alt="Juman">
			</div>
			<div class="title-section ion-text-start" *ngIf="(dataObj['banner_title_en'] || dataObj['banner_description_en'])">
				<div class="title">
					{{ dataObj['banner_title_en'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_en'] }}
				</div>
			</div>
			<div class="custom-button">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-slide>
</ion-slides>
<ion-slides #slidesGridMobileAr class="mobile-slides" (ionSlideTouchEnd)="startAutoPlay()" [options]="gridSlideOpts"
 *ngIf="gridBanner && !commonService.domDirectionLTR">
	<ion-slide *ngFor="let dataObj of gridBannerData">
		<div class="slide-content-mobile" (click)="bannerToProduct(dataObj, dataObj['redirecton_details'], dataObj['banner_id'])">
			<div class="title-scetion-mobile">
				<img fetchpriority="high" src="{{ dataObj['image_url_ar'] }}" loading="lazy" [ngClass]="dataObj['is_default_banner'] ? 'defaultbanner':'notdefaultbanner'"
				 alt="Juman">
			</div>
			<div class="title-section ion-text-start" *ngIf="(dataObj['banner_title_ar'] || dataObj['banner_description_ar'])">
				<div class="title">
					{{ dataObj['banner_title_ar'] }}
				</div>
				<div class="text pt-10">
					{{ dataObj['banner_description_ar'] }}
				</div>
			</div>
			<div class="custom-button">
				<ion-button aria-label="cta action button">
					<div>
						<ion-icon alt="juman" src="assets/icon/whiteRightArrow.svg"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-slide>
</ion-slides>
<!-- code required -->
<!-- <div class="grid-banner ion-text-center" *ngIf="gridBanner" [ngStyle]="getBannerImage()">
    <div class="banner-container">
        <div class="banner-contents pl-55">
            <div class="banner-title">
                This is a promo area
            </div>
            <div class="banner-description">
                Ut placerat ut pretium dolor sit amet adipis
            </div>
        </div>
        <div class="custom-button cta-button pr-100" routerLink="/product-listing/">
            <ion-button>
                <div class="button-text">
                    {{"ctaAction" | translate}}
                </div>
                <div>
                    <ion-icon src="assets/icon/whiteRightArrow.svg"></ion-icon>
                </div>
            </ion-button>
        </div>
    </div>
</div> -->
<!-- display the banner on product listing page -->
<div class="desktop-banner-container" *ngIf="productlistBanner && getPlatformService.isDesktop">
	<div class="product-list-container">
		<div class="banner-content">
			<div class="banner-title top-banner">
				{{ productTitle ? productTitle : bannerTitle }} <span class="more-categories" *ngIf="selectedCategories >= 1">+{{
					selectedCategories }} {{'more' | translate }}</span>
			</div>
			<div class="banner-description">
				{{ bannerDescription ? bannerDescription : '' }}
			</div>
		</div>
		<div class="banner-image-container">
			<img [src]="bannerImage ? bannerImage : '/assets/images/slider.svg'" alt="juman">
		</div>
	</div>
</div>

<div class="product-list-banner" *ngIf="productlistBanner && !getPlatformService.isDesktop">
	<div class="product-list-container">
		<img [src]="bannerImage ? bannerImage : '/assets/images/slider.svg'" alt="juman">
		<div class="banner-content">
			<div class="banner-title top-banner">
				{{ productTitle ? productTitle : bannerTitle }}
			</div>
			<div class="banner-description">
				{{ bannerDescription ? bannerDescription : '' }}
			</div>
		</div>
	</div>
</div>

<div *ngIf="multiBanner && bottomBannerData && getPlatformService.isDesktop">
	<div class="pager-container mb-20">
		<div class="pager" [class.active]="slideIndex == i" *ngFor="let data of bottomBannerData; index as i" (click)="slideTo(i)">
		</div>
	</div>
</div>

<div *ngIf="gridBanner && gridBannerData && getPlatformService.isDesktop">
	<div class="pager-container mb-20">
		<div class="pager" [class.active]="slideIndex == i" *ngFor="let data of gridBannerData; index as i" (click)="slideTo(i)">
		</div>
	</div>
</div>