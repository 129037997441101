import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { URLS } from 'src/app/_config/api.config';
import { ModalService } from 'src/app/services/modal.service';
import { ApiService } from '../../services/api-data.service';
import { CommonService } from '../../services/common.service';
import { ExportExcelService } from '../../services/export-excel.service';
import { ToastService } from '../../services/toast.service';
import { ReturnOrderMobileFilterComponent } from '../return-order-mobile-filter/return-order-mobile-filter.component';
import { ReturnOrderRequestComponent } from '../return-order-request/return-order-request.component';
import { GetPlatformService } from './../../services/get-platform.service';

@Component({
	selector: 'app-return-order-listing',
	templateUrl: './return-order-listing.component.html',
	styleUrls: ['./return-order-listing.component.scss'],
})
export class ReturnOrderListingComponent implements OnInit {
	currencySymbol: string = "SR";
	showSearch = false;
	searchText: any;
	showFilter = false;
	searchObj: any = {};
	filterObj: any = {};
	selectStatus: any = "all";
	public posts = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25];

	public count = 0;
	statusList = [
		{
			label: "all",
			value: "all"
		},
		{
			label: "approve",
			value: "APPROVED"
		},
		{
			label: "pending",
			value: "PENDING"
		},
		{
			label: "reject",
			value: "REJECTED"
		},
	];
	public itemsPerPage = 10;

	public currentPage = 1;
	perPageInterface = { cssClass: 'custom-select-dd per-page-dd' };
	selectInterface = { cssClass: 'custom-select-dd' };
	fromDate: any;
	toDate: any;
	dateRange: FormGroup;
	maxFromDate: any;
	maxDate: any;
	minToDate: any;
	todaysDate = new Date();
	orderList = [];
	isCancelPage = false;
	enableNext = false;
	@Input() showHeader: boolean;
	private subscription: Subscription = new Subscription();
	public isLoading = false;
	public isDownloading = false;
	constructor(private modalService: ModalService,
		public commonService: CommonService,
		private fb: FormBuilder,
		private datePipe: DatePipe,
		private toastService: ToastService,
		private dataService: ApiService,
		public navCtrl: NavController,
		public getPlatformService: GetPlatformService,
		public exportService: ExportExcelService,
		public translateService: TranslateService,
		private router: Router) {
		this.getPageDetails();
	}

	ngOnInit() {
		this.getPageDetails();
		this.initializeDateForm();
		let date = new Date();
		this.maxFromDate = date.setDate(this.todaysDate.getDate() - 1);
		this.maxDate = this.datePipe.transform(this.todaysDate, 'YYYY-MM-dd');
		this.maxFromDate = this.datePipe.transform(this.maxFromDate, 'YYYY-MM-dd');
	}


	getPageDetails() {
		if (this.router.url.includes("return-order-listing") || this.router.url.includes("return-order")) {
			this.isCancelPage = false;
			this.showHeader = true;
			this.getOrderList(this.searchObj);
		}
		if (this.router.url.includes("cancel-order-listing") || this.router.url.includes("cancel-order")) {
			this.showHeader = true;
			this.isCancelPage = true;
			this.getOrderList(this.searchObj);

		}
	}

	initializeDateForm() {
		this.dateRange = this.fb.group({
			fromDate: new FormControl(null),
			toDate: new FormControl(null),
		})
	}

	ionViewWillEnter() {
		this.getPageDetails();
	}

	search() {
		if (!this.searchText) return;
		this.filterObj["search"] = this.searchText;
		this.currentPage = 1;
		this.getOrderList(this.filterObj);
	}


	openFilters() {
		const obj = {
			// component: FilterComponent,
			componentProps: { title: 'Filters' },
			id: 'filterModal'
		}
		this.modalService.openModal(obj)
	}

	goToReturnRequest(id?) {
		if (id) {
			this.router.navigate(['/home/account-details/return-order-listing/return-request/', id])
		} else {
			this.router.navigate(['/home/account-details/return-order-listing/return-request/'])

		}
	}

	onChange(event): void {
		this.currentPage = event;
	}

	displayStatus(id) {
		if (id == 'CANCELLATION_REQUESTED') {
			return 'cancelRequested'
		}
		else if (id == 'RETURN_REQUESTED') {
			return 'returnRequested'
		}
		else if (id == 'DELIVERED') {
			return 'delivered'
		}
		else if (id == 'CANCELLED') {
			return 'cancelled'
		}
		else if (id == 'NOT_YET_DELIVERED') {
			return 'notYetDelivered'
		}
		else if (id == 'RETURNED') {
			return 'returned'
		}
		else if (id == 'REJECTED') {
			return 'reject'
		}
	}


	applyBtn() {
		this.filterObj = {};
		if ((this.fromDate && !this.toDate) || (!this.fromDate && this.toDate)) {
			if (!this.fromDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'From Date'."
					: 'الرجاء تحديد "من التاريخ".';
				this.toastService.displayToast(msg, null, "danger");
			}
			if (!this.toDate) {
				let msg = this.commonService.domDirectionLTR
					? "Please select 'To Date'."
					: 'الرجاء تحديد "حتى الآن".';
				this.toastService.displayToast(msg, null, "danger");
			}
			return;
		}
		if (this.fromDate && this.toDate) {
			this.filterObj["from_date"] = this.datePipe.transform(
				this.fromDate,
				"yyyy-MM-dd"
			);
			this.filterObj["to_date"] = this.datePipe.transform(
				this.toDate,
				"yyyy-MM-dd"
			);
		}
		if (this.filterObj["from_date"] && this.filterObj["to_date"]) {
			if (this.filterObj["from_date"] > this.filterObj["to_date"]) {
				let msg = this.commonService.domDirectionLTR
					? "'From Date' cannot be greater than 'To Date'."
					: 'لا يمكن أن يكون "من تاريخ" أكبر من "إلى تاريخ".';
				this.toastService.displayToast(msg, null, "danger");
				return;
			}
		}
		let isValid = false;
		if (
			isValid &&
			this.filterObj["from_date"] &&
			this.filterObj["to_date"] &&
			this.filterObj["from_date"] == this.filterObj["to_date"]
		) {
			let msg = this.commonService.domDirectionLTR
				? "'From Date' and 'To Date' cannot be same."
				: 'لا يمكن أن يتطابق "من تاريخ" و "إلى تاريخ".';
			this.toastService.displayToast(msg, null, "danger");
			return;
		}
		if (this.selectStatus == "all") {
			delete this.filterObj["return_approval_status"];
		} else {
			this.filterObj["return_approval_status"] = this.selectStatus;
		}
		this.currentPage = 1;
		this.getOrderList(this.filterObj);

	}


	clearSearch() {
		this.searchText = null;
		delete this.filterObj["search"];
		this.currentPage = 1;
		this.getOrderList(this.filterObj);
	}


	clearFilter() {
		this.fromDate = null;
		this.toDate = null;
		this.selectStatus = 'all';
		this.searchObj = {};
		this.dateRange.reset();
	}

	onDateChange(datetime) {
		datetime.cancel(true);
	}
	goBack() {
		this.navCtrl.back({ animated: false });
	}

	openFilterByModal() {
		setTimeout(() => {
			let options: any = {
				id: "filterByModal",
				componentProps: { statusList: this.statusList },
			};
			options['component'] = ReturnOrderMobileFilterComponent;
			this.modalService.openModal(options)
		}, 300);
	}

	goToRequest() {
		let options: any = {
			componentProps: { mobileView: true },
			id: "returnOrderRequest"
		};
		options['component'] = ReturnOrderRequestComponent;
		this.modalService.openModal(options)

	}

	getOrderList(params, infiniteScroll?, download?) {
		let api = this.isCancelPage ? URLS.cancelOrderList : URLS.returnOrderList;
		if (this.isLoading) {
			return
		}
		if (!download) {
			params = {
				page: this.currentPage,
				size: +this.itemsPerPage,
				...params,
			};
		}

		return new Promise((resolve, reject) => {
			if (!infiniteScroll) {
				this.isLoading = true;
			}
			this.subscription.add(
				this.dataService.get(api, params).subscribe(data => {
					if (data['code'] === 200) {
						this.isLoading = false;
						if (download) {
							let list = data["data"] && data["data"]["result"] && data["data"]["result"].length ? data["data"]["result"] : [];
							if (list.length) {
								list.forEach((element, index) => {
									delete element["paid_order_amount"];
								});
								this.exportService.exportAsExcelFile(list, "order_list");
							}
						} else {
							if (data["data"]) {
								this.enableNext = data["data"]["enable_next"] ? true : false;
								this.count = data["data"]["total_count"] ? +data["data"]["total_count"] : 0;
								let list = data["data"]["result"] && data["data"]["result"].length ? data["data"]["result"] : [];
								if (!infiniteScroll) {
									this.orderList = [];
								}
								list.forEach((element) => {
									let obj = {
										request: element.invoice_id ? element.invoice_id : 0,
										order_id: element.order_no ? element.order_no : 0,
										invoice_id: element.invoice_id ? element.invoice_id : 0,
										request_date: element.created_at,
										pickup_from: !this.isCancelPage ? element.shipping_address + ", " +
											element.shipping_address_city + "," +
											element.shipping_address_region + "," +
											element.shipping_address_country + " - " +
											element.shipping_address_zipcode : null,
										product_name: this.commonService.domDirectionLTR ? element.product_name_en : element.product_name_ar,
										order_status: element.shipping_status,//under review
									};
									this.orderList.push(obj);
								});

							}

						}
						resolve(this.orderList)
					} else {
						reject()
						this.toastService.displayToast(data['message'], null, 'danger')
					}
				}, error => {
					this.isLoading = false;
					reject()
					this.toastService.displayToast(error['message'], null, 'danger')
				})
			)
		})
	}

	loadData(event) {
		setTimeout(() => {
			if (this.enableNext) {
				this.currentPage += 1;
				this.getOrderList({}, true).then((data) => {
					event.target.complete();
				}, err => {
					event.target.complete();
				})
			}
		}, 500);
	}

	downloadExcel() {
		if (this.isDownloading) {
			return;
		}
		const params = {
			dropdown: true,
		};
		this.isDownloading = true;
		this.getOrderList(params, false, true).then(
			(data) => {
				this.isDownloading = false;
			},
			(err) => {
				this.isDownloading = false;
			}
		);
	}

	// for order navigation
	gotoOrderDetails(id, orderNo) {
		this.router.navigate(["/home/account-details/my-orders/", id], {
			state: {
				breadcrumb:
					this.translateService.instant("orderDetailsComponent.orders") +
					orderNo,
				url: "/home/account-details/my-orders/" + id,
			},
		});
	}

	gotoOrderDetailsFromMobile(id, orderNo) {
		this.navCtrl.navigateForward(['/home/order-details-mobile'], {
			queryParams: {
				invoiceId: id
			}
		})
	}
}
