import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

declare global {
	interface Window {
		fbq?: {
			(...args: any[]): void;
			callMethod?: (...args: any[]) => void;
			queue?: any[];
			loaded?: boolean;
			version?: string;
		};
		_fbq?: any;
	}
}

@Injectable({
	providedIn: 'root',
})
export class MetaPixelService {
	private pixelId = '530307929982055';

	constructor(@Inject(PLATFORM_ID) private platformId: Object,) {
		this.initializeMetaPixel();
	}

	private initializeMetaPixel(): void {
		if (!isPlatformServer(this.platformId)) {
			if (window.fbq) return; // Prevent duplicate initialization

			const script = document.createElement('script');
			script.async = true;
			script.src = 'https://connect.facebook.net/en_US/fbevents.js';
			script.onload = () => {
				// Define fbq as a callable function with additional properties
				window.fbq = function (...args: any[]) {
					if (window.fbq?.callMethod) {
						window.fbq.callMethod.apply(window.fbq, args);
					} else {
						window.fbq?.queue?.push(args);
					}
				};
				window.fbq.callMethod = window.fbq;
				window.fbq.queue = [];
				window.fbq.loaded = true;
				window.fbq.version = '2.0';

				// Initialize the pixel
				window.fbq('init', this.pixelId);
				window.fbq('track', 'PageView');
			};

			document.head.appendChild(script); // Add the script to the document
		}
	}

	trackEvent(eventName: string, params: object = {}): void {
		if (!isPlatformServer(this.platformId)) {
			if (!eventName) {
				console.error('Meta Pixel event name is missing.');
				return;
			}


			if (window.fbq) {
				window.fbq('track', eventName, params);
			} else {
				console.warn('Meta Pixel not initialized.');
			}
		}
	}
}
