// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	apiUrl: 'https://api.jumanmarket.com',
	webUrl: "https://www.jumanmarket.com",
	rsa_public_key: "$2a$10$e.oPc.dyrwRoQCpDvO9Rhe",
	supplierUrl: 'https://supplier.jumanmarket.com/login',
	appversion: "6.0", //app version
	firebaseConfig: {
		apiKey: "AIzaSyDqMUhJkuJbnrex7CNRWF32tOolh7HmW6c",
		authDomain: "juman-marketplace-production.firebaseapp.com",
		projectId: "juman-marketplace-production",
		storageBucket: "juman-marketplace-production.appspot.com",
		messagingSenderId: "881809589217",
		appId: "1:881809589217:web:b4fd5414d1451cbb9b08a0",
		measurementId: "G-8HKRLMDL82"
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
